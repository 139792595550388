import React, { useState } from "react";
import { useSpring, animated } from 'react-spring';

//util
import { IsPhone } from "../../utils/IsPhone";
const FormInputField = (props) => {
    const [isFocus, setIsFocus] = useState(false);

    const focusInput = (e) => {
        setIsFocus(true);
    }

    const blurInput = (e) => {
        if (e.target.value !== '') {
            setIsFocus(true);
        }
        else {
            setIsFocus(false);
        }
    }

    const focusAnimation = useSpring({
        to: {
            top: `${isFocus ? (IsPhone() ? -20 : -30) : 0}px`,
            transform: `scale3d(${isFocus ? 0.8 : 1},${isFocus ? 0.8 : 1},1)`
        }
    })

    const colorFocusAnimation = useSpring({
        to: {
            backgroundColor: isFocus ? "#0999B8" : "#0F57A0",
        }
    })

    const textFocusAnimation = useSpring({
        to: {
            color: isFocus ? "#0999B8" : "#0F57A0",
        }
    })

    return (
        <animated.div style={textFocusAnimation}
            className='floating-placeholder relative w-full bg-transparent mt-8 lg:mt-10 font-xinhannormal' >
            {props.largeContent ? (
                <textarea {...props.register(props.label)} onFocus={focusInput} onBlur={blurInput} required={props.required}
                    className="name-input w-full h-full text-lg sm:text-xl md:text-2xl bg-transparent outline-none overflow-y-auto resize-none">
                </textarea>) : (
                <input {...props.register(props.label)} onFocus={focusInput} onBlur={blurInput} required={props.required}
                    className="name-input w-full h-full text-lg sm:text-xl md:text-2xl bg-transparent outline-none"
                    type="text" />)
            }
            <animated.label style={focusAnimation} className="absolute left-0 w-auto text-lg sm:text-xl md:text-2xl pointer-events-none origin-top-left"
                htmlFor={props.title} >{props.Name}</animated.label>
            <animated.div style={colorFocusAnimation} className="relative h-1 w-full mt-1"></animated.div>
        </animated.div>
    )
}

FormInputField.defaultPorps = {
    largeContent: false
}

export default FormInputField;