//lib
import React from "react";
//css
import "./index.css";

const CustomerBlock = (e) => {
    return (
        <div className="relative flex flex-col justify-center 
            w-24 h-24 md:w-30 md:h-30  xl:w-36 xl:h-36">
            <img className="logoImage" src={e.logoSrc}
                alt={e.title} />
        </div>
    )
}

export default CustomerBlock;