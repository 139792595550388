import React, { useState } from 'react';
import { useSpring, animated } from 'react-spring';

import "./index.css";

const FlipCard = (e) => {
    const [isClicked, setIsClicked] = useState(false);

    const flipAnimation = useSpring({
        to: { transform: `rotateY(${isClicked ? 180 : 0}deg) scale3d(${isClicked ? 0.6 : 1},${isClicked ? 0.6 : 1},1)` }
    })

    const flipAnimationReverse = useSpring({
        to: { transform: `rotateY(${isClicked ? 0 : 180}deg) scale3d(${isClicked ? 1 : 0.6},${isClicked ? 1 : 0.6},1)` }
    })

    const onClick = (e) => {
        setIsClicked(!isClicked);
    }

    return (
        <div onClick={onClick} className='flip-card h-auto md:w-28 lg:w-32 relative ml-2 mr-2'>
            <animated.div style={flipAnimation} className='card-content absolute'>
                <img className='w-full object-cover' src={e.beforeImg} alt="before" />
            </animated.div>
            <animated.div style={flipAnimationReverse} className='card-content relative'>
                <img className='w-full object-cover' src={e.afterImg} alt="after" />
            </animated.div>
        </div>
    )
}

export default FlipCard;