import PreLoader from "../../component/PreLoader";
import CaseSection from "../../component/CaseSection";
import DisplaySection from "../../component/DisplaySection";
import Header from "../../component/Header";
import WebSwiper from "../../component/Swiper";
import Separator from "../../component/Separator";
import ServiceSection from "../../component/ServiceSection";
import CustomerSection from "../../component/CustomerSection";
import ContactSection from "../../component/ContactSection";
import Footer from "../../component/Footer";

//lib
import React, { useState } from "react";
import { Element } from 'react-scroll';
//util
import { IsPhone } from "../../utils/IsPhone";
//imageResources
const phoneBackground = "https://haiyantest-1304231816.cos.ap-guangzhou.myqcloud.com/HaiyanResources/SomeImages/phoneBackground1.webp";
const background = "https://haiyantest-1304231816.cos.ap-guangzhou.myqcloud.com/HaiyanResources/SomeImages/background1.png";


const HomePage = () => {

    const [isLoaded, setIsLoaded] = useState(false);
    const [disable, setDisable] = useState(false);

    window.onload = () => {
        setIsLoaded(true);
        setTimeout(() => {
            setDisable(true);
        }, 1000);
    }

    const displayBackground = (IsPhone ? phoneBackground : background);

    return (
        <div className="overflow-x-hidden">
            <PreLoader isLoaded={isLoaded} disable={disable} />
            <Header />
            <Element name="homePage">
                <WebSwiper isPhone={IsPhone()} />
            </Element>
            <Element name="aboutPage">
                <Separator title="关于我们" bgSrc={displayBackground} />
                <DisplaySection />
            </Element>
            <Element name="casePage">
                <CaseSection isLoaded={isLoaded} />
            </Element>
            <Element name="servicePage">
                <Separator title="服务范围" bgSrc={displayBackground} />
                <ServiceSection />
            </Element>
            <Element name="contactPage">
                <ContactSection />
            </Element>
            <Element name="customerPage">
                <CustomerSection />
            </Element>
            <Footer />
        </div >
    );
}

export default HomePage;