//lib
import React from "react";

//css
import './index.css'

const ContentBox = (props) => {

    return (
        <div className="content-box relative m-5 w-96 md:w-sm-30">
            {
                (props.isVideo ? (
                    <video className="object-cover h-80 md:h-96 w-full"
                        src="https://haiyantest-1304231816.cos.ap-guangzhou.myqcloud.com/HaiyanResources/Cases/Video/xiaoyunlongVideo.mp4"
                        autoPlay>
                        您的浏览器不支持此Video。
                    </video>
                ) : (
                    <img className="object-cover h-80 md:h-96 w-full" src={props.fileSrc} alt={props.title} />
                )
                )
            }
            <div className="text-block relative h-auto md:h-72 lg:h-56 ml-5 mr-5 -top-5 bg-white">
                <h1 className="p-2 text-haiyan-blue text-xl font-sarasaNormal md:text-2xl xl:text-4xl xl:leading-snug">{props.title}</h1>
                <div className="separate-line"></div>
                <h1 className="p-2 font-sarasaNormal text-gray-500">
                    {props.description}
                </h1>
            </div>
        </div>
    )
}

export default ContentBox;