//Use PascalCase for React components and camelCase for their instances
import React from "react";

import HomePage from "./pages/HomePage";



function App() {
  return (
    <div className="App">
      <HomePage />
    </div>
  );
}

export default App;
