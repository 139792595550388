import React from "react";

import { useState } from "react";
import { useSpring, animated, config } from "react-spring";

const CorporatePart = (props) => {

    const [isHover, setIsHover] = useState(false);

    const hoverAnimation = useSpring({
        to: { transform: `scale(${isHover ? 1.2 : 1})` },
        config: config.wobbly
    })

    const hoverIn = (e) => {
        setIsHover(true);
    }

    const hoverOut = (e) => {
        setIsHover(false);
    }

    return (
        <div className="corporate-part relative flex flex-col items-center ml-2 mr-2">
            <div className="flex flex-row items-center">
                <div className="cursor-default">
                    {props.index}
                    <div className="h-1 w-full bg-haiyan-orange"></div>
                </div>
                <animated.div className="relative flex items-center justify-center ml-3 md:ml-5 p-1 md:p-3
             h-8 w-8 sm:h-12 sm:w-12 md:h-20 md:w-20 
             rounded-full shadow-lg drop-shadow-lg"
                    onPointerEnter={hoverIn} onPointerLeave={hoverOut}
                    style={hoverAnimation}>
                    {props.icon}
                </animated.div>
            </div>
            <div className="mt-2 md:mt-4 text-sm md:text-lg font-sweiXDBold text-haiyan-blue">{props.title}</div>
        </div>
    )
}

export default CorporatePart;