//lib
import React from "react";
import { useSpring, animated } from "react-spring";
import { Player } from "@lottiefiles/react-lottie-player";
//css
import './index.css';

const PreLoader = (props) => {


    const preloaderSplitUpAnimation = useSpring({
        to: { transform: `translate3d(0,${props.isLoaded ? -100 : 0}%,0)` },
        delay: 500
    })

    const preloaderSplitDownAnimation = useSpring({
        to: { transform: `translate3d(0,${props.isLoaded ? 100 : 0}%,0)` },
        delay: 500
    })

    const loadingItemAnimation = useSpring({
        to: {
            transform: `scale(${props.isLoaded ? 0 : 100}%)`,
            opacity: props.isLoaded ? 0.5 : 1
        }
    })

    return (
        <div>
            {
                props.disable ? (<div className="hidden"></div>) : (
                    <div className="preloader fixed h-screen w-screen z-40">
                        <animated.div className="preloader-up h-1/2 w-full bg-haiyan-blue"
                            style={preloaderSplitUpAnimation}></animated.div>
                            <animated.div className="loading-animation absolute w-96 
                        h-96 z-50 top-0 bottom-0 m-auto"
                                style={loadingItemAnimation}>
                                <Player autoplay loop className="real-animation origin-center"
                                    src="https://haiyantest-1304231816.cos.ap-guangzhou.myqcloud.com/HaiyanResources/Animation/loading.json">
                                </Player>
                            </animated.div>
                        <animated.div className="preloader-down h-1/2 w-full bg-haiyan-blue"
                            style={preloaderSplitDownAnimation}></animated.div>
                    </div>)
            }
        </div>
    )
}

export default PreLoader;