import React from "react";

import { useEffect, useState } from 'react';
import { useSpring, animated, config } from 'react-spring';

//css
import "./index.css"
//resource
import HorizontalLogo from "../../svgs/horizontalLogo";
//component
import NavButton from '../NavButton';
import NavHamburger from "../NavHamburger";
const Header = () => {

    const [visible, setVisible] = useState(true);
    const [expand, setExpand] = useState(false);

    useEffect(() => {
        const threshold = 0;
        let lastScrollY = window.scrollY;
        let ticking = false;
        const updateScrollDir = () => {
            const scrollY = window.scrollY;
            if (Math.abs(scrollY - lastScrollY) < threshold) {
                ticking = false;
                return;
            }
            setVisible(scrollY > lastScrollY ? false : true);
            lastScrollY = scrollY > 0 ? scrollY : 0;
            ticking = false;
        }

        const onScroll = () => {
            if (!ticking) {
                window.requestAnimationFrame(updateScrollDir);
                ticking = true;
            }
        }

        window.addEventListener("scroll", onScroll);
        return () => window.removeEventListener("scroll", onScroll);
    }, [visible]);

    const scrollAnimation = useSpring({
        to: { transform: `translate3d(0,${visible ? 0 : -100}%,0)` },
        config: config.default
    })

    const verticalAnimation = useSpring({
        to: { transform: `translate3d(${(visible && expand) ? 0 : -100}%,0,0)` },
        config: config.default
    })


    return (
        <animated.div style={scrollAnimation}
            className="w-screen fixed bg-white bg-opacity-60 z-30">
            <header className="App-header flex flex-row h-14 lg:h-24 items-center justify-around sm:justify-evenly">
                <NavHamburger expand={expand} setExpand={setExpand} />
                <a href="#hompage">
                    <HorizontalLogo />
                </a>
                <div className="hidden sm:flex flex-row items-center">
                    <NavButton url="aboutPage" name="关于我们" />
                    <NavButton url="casePage" name="案例展示" />
                    <NavButton url="servicePage" name="服务范围" />
                    <NavButton important={true} url="contactPage" name="免费咨询" />
                </div>
                <animated.div className="vertical-menu flex flex-col items-center justify-evenly
                            bg-white bg-opacity-60
                            absolute left-0 top-14 sm:hidden"
                    style={verticalAnimation}>
                    <NavButton url="aboutPage" name="关于我们" />
                    <NavButton url="casePage" name="案例展示" />
                    <NavButton url="servicePage" name="服务范围" />
                    <NavButton important={true} url="contactPage" name="免费咨询" />
                </animated.div>
            </header>
        </animated.div>
    )
}

export default Header;