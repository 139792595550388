import React from "react";

//component
import FlipCard from "../FlipCard";
import NavButton from "../NavButton";

const BottomInfo = (e) => {
    return (
        <div className="bottom flex flex-row items-center justify-evenly mt-6">
            <div className="slogan relative flex flex-col w-auto items-center">
                <div className="relative flex flex-row w-screen md:w-auto justify-center">
                    <NavButton url="aboutPage" name="关于我们" />
                    <NavButton url="casePage" name="案例展示" />
                    <NavButton url="servicePage" name="服务范围" />
                    <NavButton url="contactPage" name="联系我们" />
                </div>
            </div>
            <div className="flipcard-group hidden md:flex flex-row w-1/2 justify-evenly">
                <FlipCard beforeImg="https://haiyantest-1304231816.cos.ap-guangzhou.myqcloud.com/HaiyanResources/FlipImage/QiyeWechatIcon.png"
                    beforeText='点击查看'
                    afterImg="https://haiyantest-1304231816.cos.ap-guangzhou.myqcloud.com/HaiyanResources/FlipImage/QiyeWechatQRCode.png"
                    afterText='添加企业微信' />
                <FlipCard beforeImg="https://haiyantest-1304231816.cos.ap-guangzhou.myqcloud.com/HaiyanResources/FlipImage/WechatPublic.png"
                    beforeText='点击查看'
                    afterImg="https://haiyantest-1304231816.cos.ap-guangzhou.myqcloud.com/HaiyanResources/FlipImage/WechatPublicQRCode.png"
                    afterText='关注公众号' />
                <FlipCard beforeImg="https://haiyantest-1304231816.cos.ap-guangzhou.myqcloud.com/HaiyanResources/FlipImage/MiniProgramIcon.png "
                    beforeText='点击查看'
                    afterImg="https://haiyantest-1304231816.cos.ap-guangzhou.myqcloud.com/HaiyanResources/FlipImage/MiniProgramQRCode.png"
                    afterText='进入小程序' />
            </div>
        </div>
    )
}

export default BottomInfo;