import React from "react";
import { animated, useSpring } from "react-spring";

//css
import './index.css'

const HoverButton = (e) => {

    const textAnimation1 = useSpring({
        to: { transform: `translate3d(0,${e.submitState === 200 ? -10 : 0}rem,0)` }
    })

    const textAnimation2 = useSpring({
        to: { transform: `translate3d(0,${e.submitState === 200 ? 0 : 10}rem,0)` }
    })

    const backgroundAnimation = useSpring({
        to: {
            backgroundSize: `${e.submitState === 200 ? 100 : 0}%,${e.submitState === 200 ? 100 : 0}%`,
            color:`${e.submitState === 200 ? '#fff' : '#0F57A0'}`
        }
    })

    return (
        <animated.button style={backgroundAnimation} className="submit-button relative
        border-4 border-haiyan-blue text-lg md:text-2xl 
        m-5 md:mb-5 md:mt-10 font-sweiXDBold
        w-24 h-10 md:w-44 md:h-14 rounded-xl overflow-hidden" type="submit">
            <animated.h1 style={textAnimation1} className="title title1 h-5 md:h-9">提交信息</animated.h1>
            <animated.h1 style={textAnimation2} className="title title2 h-5 md:h-9">海燕马上就来!</animated.h1>
        </animated.button>
    )
}

export default HoverButton;