//lib
import React from "react"

//component
import HorseLight from "../HorseLight";
import AnimateIcon from "../AnimateIcon";

//css
import "./index.css"

const ContactMethod = (e) => {
    return (
        <div>
            <div className="pcSide contact-message hidden md:flex flex-row h-72 justify-evenly items-center">
                <div className="flex flex-row justify-center items-center">
                    <section className="active-contact flex flex-col items-end relative -top-8 font-sarasaNormal leading-relaxed">
                        <h1 className="text-2xl flex flex-row items-baseline mb-5 -mr-16">
                            <h1 className="text-lg relative right-3 font-sarasaNormal">We are Ready!</h1>
                            我们
                            <h1 className="emphasize-text">准备</h1>
                            好了
                        </h1>
                        <h1 className="flex flex-row items-baseline mb-5 -mr-10 text-5xl">联系方式看这里</h1>
                        <HorseLight />
                    </section>
                    <svg className="slope" xmlns="http://www.w3.org/2000/svg" version="1.1">
                        <line x1="100" y1="0" x2="0" y2="300" />
                    </svg>
                    <div className="relative flex flex-col justify-evenly items-start top-8">
                        <div className="relative flex flex-row items-center">
                            <lord-icon style={{ width: 4 + 'rem', height: 4 + 'rem' }}
                                src="https://cdn.lordicon.com/rhvddzym.json"
                                trigger="loop"
                                delay="100"
                                colors="primary:#FFFFFF,secondary:#a5abbd">
                            </lord-icon>
                            <a href="mailto:business@haiyan-ads.cn" className="ml-3 font-sarasaNormal text-base lg:text-2xl">business@haiyan-ads.cn</a>
                        </div>
                        <div className="relative -ml-10 flex flex-row items-center">
                            <lord-icon style={{ width: 4 + 'rem', height: 4 + 'rem' }}
                                src="https://cdn.lordicon.com/zpxybbhl.json"
                                trigger="loop"
                                delay="500"
                                colors="primary:#FFFFFF,secondary:#a5abbd">
                            </lord-icon>
                            <a href='tel:07526733990' className="z-20 ml-3 font-sarasaNormall text-base lg:text-2xl">0752-6733990</a>
                        </div>
                        <div className="relative -ml-16 flex flex-row items-center">
                            <lord-icon style={{ width: 4 + 'rem', height: 4 + 'rem' }}
                                src="https://cdn.lordicon.com/zzcjjxew.json"
                                trigger="loop"
                                delay="1000"
                                colors="primary:#FFFFFF,secondary:#a5abbd">
                            </lord-icon>
                            <a href="https://uri.amap.com/marker?position=114.278487, 23.172275" className="ml-3 font-sarasaNormal text-base lg:text-2xl">惠州市博罗县博罗大道中793号</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex flex-col items-center md:hidden">
                <div className="mobileSide flex flex-col justify-start h-auto w-screen tracking-wider pb-32">
                    <div className="flex flex-row items-center justify-center mt-5">
                        <h1 className="verticleText text-base relative font-sarasaNormal">We're Ready!</h1>
                        <section className="active-contact flex flex-col w-auto 
                            items-start relative font-sarasaNormal">
                            <h1 className="flex flex-row items-baseline mb-2 text-5xl">选择联系方式</h1>
                            <HorseLight />
                        </section>
                    </div>
                </div>
                <div className="upper-text relative flex flex-row justify-evenly
                            bg-haiyan-blue items-center w-11/12 -mt-24 mb-5 pt-6">
                    <div className="relative items-center
                      text-white text-xl font-sarasaNormal m-2 p-2">
                        <a className="flex flex-col items-center" href="mailto:business@haiyan-ads.cn">
                            <AnimateIcon animationURL="https://cdn.lordicon.com/rhvddzym.json" />
                            邮箱
                        </a>
                    </div>
                    <div className="relative items-center
                      text-white text-xl font-sarasaNormal m-2 p-2">
                        <a className="flex flex-col items-center" href='tel:07526733990'>
                            <AnimateIcon animationURL="https://cdn.lordicon.com/zpxybbhl.json" />
                            电话
                        </a>
                    </div>
                    <div className="relative items-center 
                      text-white text-xl font-sarasaNormal m-2 p-2">
                        <a className="flex flex-col items-center" href="https://uri.amap.com/marker?position=114.278487, 23.172275">
                            <AnimateIcon animationURL="https://cdn.lordicon.com/zzcjjxew.json" />
                            地图
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactMethod;