import React from "react";
//css
import "./index.css"

//Component
import Form from "../Form";
import ContactMethod from "../ContactMethod";

const ContactSection = () => {

    return (
        <div className="contact-section h-auto">
            <ContactMethod />
            <div className="title flex flex-col items-center">
                <div className="coTitleEn flex flex-col 
                        text-4xl lg:text-5xl mt-6 mb-3">
                    <h1 className="flex flex-col items-center">
                        Make a Request
                        <h1 className="text-2xl lg:text-4xl text-haiyan-blue font-sweiXDBold mt-2">
                            提 交 需 求
                        </h1>
                    </h1>
                    <div className="w-auto h-1 bg-haiyan-yellow"></div>
                </div>
            </div>
            <div className="flex relative flex-col items-center">
                <div className="flex flex-row relative justify-center">
                    <Form />
                    <img className="w-1/2 object-contain hidden sm:block" src="https://haiyantest-1304231816.cos.ap-guangzhou.myqcloud.com/Vectors/MessageComing.png" alt="message" />
                </div>
            </div>
        </div>
    )
}

export default ContactSection;