//lib
import React, { useState } from "react";
import { HiOutlineExclamationCircle } from "react-icons/hi";
import { useSpring, animated } from "react-spring";

//utils
import { isBigScreen } from "../../utils/IsPhone";
const PrivacyWindow = (e) => {

    const [toggle, setToggle] = useState(false);

    const toggleFunc = (e) => {
        setToggle(!toggle);
    }

    const popupAnimation = useSpring({
        to: {
            opacity: toggle ? 1 : 0,
            transform: `translate3d(${isBigScreen ? (toggle ? 150 : 0) : 0}px,
            ${isBigScreen ? 0 : (toggle ? -150 : 0)}px,
            0)`
        }
    })

    return (
        <div className="relative flex flex-row items-center">
            <animated.div className="absolute -left-24 md:left-0 select-none w-52 md:w-96 h-auto bg-white rounded-xl p-3 text-haiyan-deepBlue" style={popupAnimation}>
                本平台对保护用户个人信息高度重视，
                本平台将会采取合理的措施保护用户的个人信息。
                除法律法规规定的情形外，
                未经用户许可本平台不会向第三方
                公开、透露用户个人信息。
            </animated.div>
            <div className="relative w-4 h-4 lg:w-6 lg:h-6 cursor-pointer" onPointerEnter={toggleFunc} onPointerLeave={toggleFunc}>
                <HiOutlineExclamationCircle className="w-full h-full text-haiyan-deepBlue" />
            </div>
        </div>
    )
}

export default PrivacyWindow;