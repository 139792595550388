import React from "react";

const AnimateIcon = (e) => {
    return (
        <lord-icon style={{ width: 5 + 'rem', height: 5 + 'rem' }}
            src={e.animationURL}
            trigger="loop"
            delay="100"
            colors="primary:#FFFFFF,secondary:#F1F1F1">
        </lord-icon>
    )
}

export default AnimateIcon;