import React from "react";

import { useState } from 'react'
import { useSpring, animated } from 'react-spring'
import { Link } from 'react-scroll'
const NavButton = (props) => {
    const [isHover, setIsHover] = useState(false)
    const HoverTextAnimation = useSpring({
        to: {
            color: isHover ? '#0F57A0' : '#585858'
        }
    })
    const HoverLineAnimation = useSpring({
        to: {
            transform: `scaleX(${isHover ? 1 : 0})`
        }
    });

    const hoverEnter = () => {
        setIsHover(true);
    }

    const hoverLeave = () => {
        setIsHover(false);
    }

    return (
        <div className="flex flex-col items-center leading-none">
            <Link activeClass="active" className={props.url} to={props.url}
                smooth={true}
                onMouseEnter={hoverEnter} onMouseLeave={hoverLeave}>
                {
                    props.important ?
                        (
                            <animated.h1 className="text-base md:text-base xl:text-2xl
                                              bg-haiyan-blue text-white
                                                rounded-xl p-1 md:p-2
                                                ml-2 mr-2 lg:ml-5 lg:mr-5 
                                                font-sweiXDBold
                                                cursor-pointer">
                                {props.name}
                            </animated.h1>
                        )
                        :
                        (
                            <animated.h1 className="text-base md:text-base xl:text-2xl
                             p-1
                             ml-2 mr-2 lg:ml-5 lg:mr-5 
                             cursor-pointer font-sweiXDBold"
                                style={HoverTextAnimation}>{props.name}
                            </animated.h1>
                        )
                }
            </Link>
            {
                props.important ?
                    (
                        <animated.span className="h-1 w-16 md:w-16 lg:w-24 mt-1 lg:mt-2" />
                    )
                    :
                    (
                        <animated.span className="h-1 md:w-16 lg:w-24 mt-1 lg:mt-2 bg-haiyan-blue origin-left"
                            style={HoverLineAnimation} />
                    )
            }
        </div>
    )
}

export default NavButton;