//css
import './index.css'
//lib
import React from 'react';
import { useTrail, animated, useSpring } from 'react-spring';
import { useInView } from "react-intersection-observer";
import { Link } from 'react-scroll'
//css
import "./index.css";

const AnimatedText1 = (props) => {

    const { ref, inView } = useInView({
        threshold: 0
    });

    const items = [props.text1, props.text2];
    const trail = useTrail(items.length, {
        opacity: inView ? 1 : 0.5,
        transform: `translate3d(0,${inView ? 0 : 1}rem,0) scale3d(1,${inView ? 1 : 0},1)`,
        delay: 700
    });
    const buttonAnimation = useSpring({
        opacity: inView ? 1 : 0.5,
        transform: `translate3d(0,${inView ? 0 : 1}rem,0) scale3d(1,${inView ? 1 : 0},1)`,
        delay: 1000
    })

    return (
        <animated.div ref={ref}
            className="animatedTitle relative w-auto
            text-xl sm:text-2xl xl:text-3xl font-sarasaNormal">
            {trail.map(({ transform, opacity }, index) => (
                <animated.div key={items[index]} className="trails-text"
                    style={{ opacity }}>
                    <animated.h1 className="relative left-10 sm:m-1 xl:m-2 tracking-wide" style={{ transform }}>
                        {items[index]}
                    </animated.h1>
                </animated.div>
            ))
            }
            <animated.div style={buttonAnimation}
                className='relative cursor-pointer projectButton 
                     text-white bg-haiyan-blue
                     font-sarasaNormal text-base md:text-xl xl:text-2xl
                     text-center rounded-2xl p-1 xl:p-2
                     w-20 sm:w-28 xl:w-32
                     left-10 xl:left-14 top-3 xl:top-6'>
                <Link to='contactPage' smooth={true}>
                    定制方案
                </Link>
            </animated.div>
        </animated.div >
    )
}

export default AnimatedText1