import React, { useState } from "react";
import { useForm } from "react-hook-form";
import axiosInstance from "../../utils/http-common";

//css
import './index.css'

//component
import FormInputField from "../FormInputField";
import HoverButton from "../HoverButton";
import PrivacyWindow from "../PrivacyWindow";

const Form = () => {
    const { register, handleSubmit } = useForm();
    const [submitState, setSubmitState] = useState(0);

    const onSubmit = (e) => {
        const customerObject = {
            name: e.name,
            phone: e.phone,
            address: e.address,
            industry: e.industry,
            detail: e.detail
        }
        axiosInstance.post('/create', customerObject)
            .then((res) => {
                setSubmitState(res.status);
            }).catch((err) => {
                console.log(err.toJSON());
            });
    };

    return (
        <div className="relative">
            <form onSubmit={handleSubmit(onSubmit)} className="contact-form relative
               flex flex-col items-center justify-between
               w-72 md:w-11/12 ml-5">
                <FormInputField register={register} required={true} label="name" Name="如何称呼*" />
                <FormInputField register={register} required={true} label="phone" Name="联系方式(手机或邮箱)*" />
                <FormInputField register={register} required={false} label="address" Name="项目地点(现场勘探)" />
                <FormInputField register={register} required={false} label="industry" Name="经营行业(了解竞品)" />
                <div className="flex flex-row items-baseline">
                    <HoverButton submitState={submitState} />
                    <PrivacyWindow />
                </div>
            </form>
        </div>
    )
}

export default Form;