//lib
import React from "react";
//component
import CustomerPart from "../CustomerPart";
//css
import "./index.css"

const CustomerSection = (e) => {
    return (
        <div className="mt-6">
            <div className="separateLine w-full h-1"></div>
            <div className="customerSection relative overflow-hidden">
                <div className="bgCircle">
                </div>
                <div className="bgCircle2"></div>
                <div className="coTitle flex flex-col items-center">
                    <div className="coTitleEn flex flex-col 
            text-4xl lg:text-5xl mb-3">
                        <h1 className="flex flex-col items-center">
                            Cooparate Clients
                            <h1 className="coTitleCn text-2xl lg:text-4xl text-haiyan-blue font-sweiXDBold mt-2">
                                海 燕 · 合 作 客 户
                            </h1>
                        </h1>
                        <div className="w-full h-1 bg-haiyan-yellow"></div>
                    </div>
                </div>
                <CustomerPart />
            </div>
            <div className="separateLine w-full h-1"></div>
        </div>
    )
}

export default CustomerSection;