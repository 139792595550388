import React from "react";

import { useState } from "react";
import { animated, useSpring } from "react-spring"


const ContentBox2 = (props) => {
    const [mouseDirection, setMouseDirection] = useState(0);
    const [mouseState, setMouseState] = useState(false);

    const MouseEnterDirection = (e) => {
        const target = e.currentTarget;

        const w = target.offsetWidth;
        const h = Math.round(target.offsetHeight);

        const mx = Math.round(e.pageX - (window.scrollX + target.getBoundingClientRect().left));
        const my = Math.round(e.pageY - (window.scrollY + target.getBoundingClientRect().top));

        const x = (mx - w / 2) * (w > h ? (h / w) : 1);
        const y = (my - h / 2) * (h > w ? (w / h) : 1);

        const direction = Math.round((((Math.atan2(y, x) * (180 / Math.PI)) + 180) / 90) + 3) % 4;
        setMouseDirection(direction);
        setMouseState(true);
    }

    const MouseLeaveDirection = (e) => {
        const target = e.currentTarget;

        const w = target.offsetWidth;
        const h = Math.round(target.offsetHeight);

        const mx = Math.round(e.pageX - (window.scrollX + target.getBoundingClientRect().left));
        const my = Math.round(e.pageY - (window.scrollY + target.getBoundingClientRect().top));

        const x = (mx - w / 2) * (w > h ? (h / w) : 1);
        const y = (my - h / 2) * (h > w ? (w / h) : 1);

        const direction = Math.round((((Math.atan2(y, x) * (180 / Math.PI)) + 180) / 90) + 3) % 4;

        setMouseDirection(direction);
        setMouseState(false);
    }

    const directionAnimation = useSpring({
        from: {
            transform: `translate3d(${(mouseDirection === 3 ? -60 : (mouseDirection === 1 ? 60 : 0))}rem,
                        ${(mouseDirection === 0 ? -50 : (mouseDirection === 2 ? 50 : 0))}rem,
                        0)`
        },
        to: {
            transform: `translate3d(${mouseState ? 0 : (mouseDirection === 3 ? -60 : (mouseDirection === 1 ? 60 : 0))}rem,
                        ${mouseState ? 0 : (mouseDirection === 0 ? -50 : (mouseDirection === 2 ? 50 : 0))}rem,
                        0)`
        },
        reset: (mouseState ? true : false)
    })

    return (
        <div id="content-box"
            className="content-box relative md:w-full lg:w-11/12 md:h-96 lg:h-108 xl:h-124 overflow-hidden"
            onMouseEnter={MouseEnterDirection} onMouseLeave={MouseLeaveDirection}>
            {
                (props.isVideo ? (
                    <video className="h-full w-full object-cover" loop muted autoPlay
                        src={props.fileSrc}>
                        您的浏览器不支持此Video。
                    </video>
                ) : (
                    <img className="h-full w-full object-cover" src={props.fileSrc} alt={props.title} />
                )
                )
            }
            <animated.div style={directionAnimation}
                className="flex flex-col items-center justify-center
                text-center absolute bottom-0 w-full
                pointer-events-none bg-white bg-opacity-80 text-haiyan-blue">
                <h1 className="title text-xl lg:text-2xl 2xl:text-4xl md:m-2">{props.title}</h1>
                <h1 className="description text-gray-500 md:block md:m-1 lg:m-3 text-base lg:text-xl 2xl:text-2xl">
                    {props.description}
                </h1>
            </animated.div>
        </div>
    )
}

export default ContentBox2;