import './index.css'
import React from "react"
const BubbleBackground = () => {
    return (
        <div className="relative bg-bubbles h-full w-full z-0">
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
        </div>
    )
}

export default BubbleBackground;