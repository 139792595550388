import React from "react";

const ServiceText = (props) => {
    return (
        <div className="flex flex-row items-center">
            <div className="w-4 h-4 mr-2 bg-haiyan-orange rounded-full"></div>
            <p className="text-base md:text-xl font-sarasaNormal">{props.text}</p>
        </div>
    )
}

export default ServiceText;