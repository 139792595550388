import React from "react";

import './CaseSection.css'
import CaseMenu from '../CaseMenu'
import ContentBox from '../ContentBox';
import ContentBox2 from '../ContentBox2'
import { useEffect, useState } from 'react'
import { useSprings, animated, config } from 'react-spring'

const Contents = [
    [
        {
            'title': '罗兰·德式',
            'fileSrc': 'https://haiyantest-1304231816.cos.ap-guangzhou.myqcloud.com/HaiyanResources/Cases/Images/WEBP/WEBP/rolands1.webp',
            'description': '门头采用黑色铝塑板封底，正面字体使用无边发光字。设计风格给人一种时尚感。'
        },
        {
            'title': '名花贵族',
            'fileSrc': 'https://haiyantest-1304231816.cos.ap-guangzhou.myqcloud.com/HaiyanResources/Cases/Images/WEBP/WEBP/minghuaguizu.webp',
            'description': '门头采用铝塑板封底，还嵌入了铝扣板增加立体感。字体使用的是发光字。'
        },
        {
            'title': '中国人寿',
            'fileSrc': 'https://haiyantest-1304231816.cos.ap-guangzhou.myqcloud.com/HaiyanResources/Cases/Images/WEBP/WEBP/zhongguorenshou.webp',
            'description': '门头使用的是铝塑板封底，较难处理的是门头弧度造型。'
        },
        {
            'title': '小云龙食家',
            'fileSrc': 'https://haiyantest-1304231816.cos.ap-guangzhou.myqcloud.com/HaiyanResources/Cases/Video/xiaoyunlongVideo.mp4',
            'gifSrc': 'https://haiyantest-1304231816.cos.ap-guangzhou.myqcloud.com/HaiyanResources/Cases/GIF/xiaoyunlong.gif',
            'description': '小云龙食家的招牌非常大气，绵延的发光祥云图案以及各种发光源的点缀，给人带来非常震撼的视觉冲击。',
            'isVideo': true
        }
    ],
    [
        {
            'title': '佰合口腔',
            'fileSrc': 'https://haiyantest-1304231816.cos.ap-guangzhou.myqcloud.com/HaiyanResources/Cases/Images/WEBP/WEBP/baihekouqiang.webp',
            'description': '佰合口腔大门处的软膜灯箱，最长处达到7米，安装后亮灯画面效果清晰。'
        },
        {
            'title': '罗兰·德式',
            'fileSrc': 'https://haiyantest-1304231816.cos.ap-guangzhou.myqcloud.com/HaiyanResources/Cases/Images/WEBP/WEBP/rolands3.webp',
            'description': '店铺内的软膜灯箱，除了展示商品外，还提供照明作用，对内部起装饰作用。'
        },
        {
            'title': '左邻右舍',
            'fileSrc': 'https://haiyantest-1304231816.cos.ap-guangzhou.myqcloud.com/HaiyanResources/Cases/Images/WEBP/WEBP/zuolingyoushe.webp',
            'description': '除了上方的铝塑板门头外，下方还有一个展示软膜灯箱，晚上亮灯画面效果良好。'
        },
        {
            'title': '音乐餐吧',
            'fileSrc': 'https://haiyantest-1304231816.cos.ap-guangzhou.myqcloud.com/HaiyanResources/Cases/Images/WEBP/WEBP/canba.webp',
            'description': '六边形的造型给软膜灯箱的安装带来一定难度，通过多次评估协调终于完工。'
        }
    ],
    [
        {
            'title': '众成食用菌',
            'fileSrc': 'https://haiyantest-1304231816.cos.ap-guangzhou.myqcloud.com/HaiyanResources/Cases/Images/WEBP/WEBP/yinshua/zhongchengxuanchuanhuace.webp',
            'description': '众成食用菌宣传小册子，多页印刷，用来参展宣传使用。'
        },
        {
            'title': '活动背胶',
            'fileSrc': 'https://haiyantest-1304231816.cos.ap-guangzhou.myqcloud.com/HaiyanResources/Cases/Images/WEBP/WEBP/yinshua/haiyanbeijiao.webp',
            'description': '室内背胶，可以粘贴在墙壁上用来宣传店铺活动。'
        },
        {
            'title': '海燕二维码',
            'fileSrc': 'https://haiyantest-1304231816.cos.ap-guangzhou.myqcloud.com/HaiyanResources/Cases/Images/WEBP/WEBP/yinshua/haiyanqrcode.webp',
            'description': '印刷二维码，粘贴后展示给客户扫码。'
        },
        {
            'title': '海报展架',
            'fileSrc': 'https://haiyantest-1304231816.cos.ap-guangzhou.myqcloud.com/HaiyanResources/Cases/Images/WEBP/WEBP/yinshua/zhanjia.webp',
            'description': '铝合金展架，搭配海报KT板给店面宣传活动。'
        }
    ],
    [
        {
            'title': '党建宣传栏',
            'fileSrc': 'https://haiyantest-1304231816.cos.ap-guangzhou.myqcloud.com/HaiyanResources/Cases/Images/WEBP/WEBP/biaoshi/biaoshilan.webp',
            'description': '党建宣传栏，烤漆工艺，切割花纹，高质量镀锌板。'
        },
        {
            'title': '100周年建党',
            'fileSrc': 'https://haiyantest-1304231816.cos.ap-guangzhou.myqcloud.com/HaiyanResources/Cases/Images/WEBP/WEBP/biaoshi/100zhounian.webp',
            'description': '100周年党建庆祝标识，宏伟壮观，采用镀锌铁板加烤漆工艺。'
        },
        {
            'title': '潼湖仲恺雕塑',
            'fileSrc': 'https://haiyantest-1304231816.cos.ap-guangzhou.myqcloud.com/HaiyanResources/Cases/Video/tonghudiaoshu.mp4',
            'gifSrc': 'https://haiyantest-1304231816.cos.ap-guangzhou.myqcloud.com/HaiyanResources/Cases/Images/WEBP/WEBP/biaoshi/zhongkaiDiaoshu.webp',
            'description': '渐变色烤漆工艺，搭配后面65米*3米的围挡，实在是有气势。',
            'isVideo': true
        },
        {
            'title': '文明实践中心标识',
            'fileSrc': 'https://haiyantest-1304231816.cos.ap-guangzhou.myqcloud.com/HaiyanResources/Cases/Images/WEBP/WEBP/biaoshi/biaoshi1.webp',
            'description': '宣传六传六习，采用木纹烤漆等工艺。'
        }
    ],
    [
        {
            'title': '金龙路标牌',
            'fileSrc': 'https://haiyantest-1304231816.cos.ap-guangzhou.myqcloud.com/HaiyanResources/Cases/Images/WEBP/WEBP/biaopai/jinlonglubiaoshi.webp',
            'description': '金龙路交安工程，安装了护栏标牌，警示杆，保证通过各种测试。'
        },
        {
            'title': '注意塌方警示牌',
            'fileSrc': 'https://haiyantest-1304231816.cos.ap-guangzhou.myqcloud.com/HaiyanResources/Cases/Images/WEBP/WEBP/biaopai/biaopai1.webp',
            'description': '注意塌方标牌，赶工制作质量依然有保证，支持各种内容定制。'
        },
        {
            'title': '220乡道警示路牌',
            'fileSrc': 'https://haiyantest-1304231816.cos.ap-guangzhou.myqcloud.com/HaiyanResources/Cases/Images/WEBP/WEBP/biaopai/220xiangdao.webp',
            'description': 'F杆路牌，多年制作安装经验，值得信赖。'
        },
        {
            'title': '福园路标牌',
            'fileSrc': 'https://haiyantest-1304231816.cos.ap-guangzhou.myqcloud.com/HaiyanResources/Cases/Images/WEBP/WEBP/biaopai/fuyuanlu.webp',
            'description': '福园路X195园洲段改建工程，安装大型F杆路牌，四类反光膜及足厚的钢铁材料。'
        }
    ]
]

const CaseSection = (props) => {
    const [menuIndex, setMenuIndex] = useState(0);
    const [showExpand, setShowExpand] =
        useState((window.innerWidth > 767) ? true : false);

    const handleIndexChange = (e) => {
        setMenuIndex(e);
    };

    useEffect(() => {
        if (props.isLoaded) {
            if (window.innerWidth > 767) {
                const contentBoxes = document.getElementsByName("content-grid");
                const menuSection = document.getElementById("menu-section");
                if (contentBoxes) {
                    const style = window.getComputedStyle(contentBoxes[0]);
                    menuSection.style.height = style.height;
                }
                setShowExpand(true);
            } else {
                const contentBoxes = document.getElementsByName("content-grid");
                const menuSection = document.getElementById("menu-section");
                if (contentBoxes) {
                    const style = window.getComputedStyle(contentBoxes[0]);
                    menuSection.style.height = style.height;
                }
                setShowExpand(false);
            }
        }
    }, [props.isLoaded]);

    window.addEventListener('resize', () => {
        if (props.isLoaded) {
            if (window.innerWidth > 767) {
                const contentBoxes = document.getElementsByName("content-grid");
                const menuSection = document.getElementById("menu-section");
                const style = window.getComputedStyle(contentBoxes[0]);
                menuSection.style.height = style.height;
                setShowExpand(true);
            } else {
                const contentBoxes = document.getElementsByName("content-grid");
                const menuSection = document.getElementById("menu-section");
                const style = window.getComputedStyle(contentBoxes[0]);
                menuSection.style.height = style.height;
                setShowExpand(false);
            }
        }
    }
    )


    const [menuAnimations] = useSprings(5, i => ({
        config: config.stiff,
        to: {
            transform: `translate3d(${(i === menuIndex) ? 0 : 105}vw,0,0)`
        }
    }), [menuIndex]
    );


    return (
        <div className='overflow-hidden-x'>
            <CaseMenu onIndexChange={handleIndexChange} menuIndex={menuIndex} title="案例展示" />
            <div id="menu-section" className='menu-section relative overflow-hidden md:m-3'>
                {menuAnimations.map((value, index) => (
                    <animated.div name="content-grid"
                        style={value}
                        className="content-grid 
                        absolute w-full h-auto flex flex-col justify-center items-center
                        md:grid md:grid-cols-2 md:gap-y-4 md:gap-x-4 xl:gap-y-6 xl:gap-x-6 
                        xl:mb-4 xl:mt-4
                        md:text-center
                        md:justify-items-center md:items-center">
                        {
                            Contents[index].map((value, index) => {
                                return (
                                    showExpand ?
                                        (
                                            <ContentBox2
                                                title={value.title} fileSrc={value.fileSrc} description={value.description} isVideo={value.isVideo} />
                                        ) : (
                                            <ContentBox
                                                title={value.title} fileSrc={value.isVideo ? (showExpand ? value.fileSrc : value.gifSrc) : value.fileSrc} description={value.description} isVideo={showExpand ? value.isVideo : false} />)
                                )
                            }
                            )
                        }
                    </animated.div>
                ))}
            </div>
        </div>
    )
}

export default CaseSection