import { animated, config, useSprings } from '@react-spring/web';
import React, { useState } from 'react';
import { Parallax, Background } from 'react-parallax';


const CaseMenu = (props) => {
    const menus = ["门头招牌", "软膜灯箱", "线下物料", "标牌标识", "交安工程"];

    const [curIndex, setCurIndex] = useState(0);

    const [springs] = useSprings(menus.length, i => ({
        config: config.gentle,
        to: {
            color: curIndex === i ? '#F15A24' : '#FFFFFF'
        },
    }), [curIndex]
    );

    const MenuClicked = (e) => {
        setCurIndex(Number(e.target.dataset.index));
        props.onIndexChange(Number(e.target.dataset.index));
    }

    return (
        <Parallax className="flex relative items-center justify-center h-24 md:h-32"
            bgImage="https://haiyantest-1304231816.cos.ap-guangzhou.myqcloud.com/HaiyanResources/SomeImages/phoneBackground1.png"
            blur={{ min: -1, max: 1 }}
            strength={250}>
            <Background>
            </Background>
            <div className="title text-center m-2 md:m-4">
                <p className="font-sarasaNormal text-2xl md:text-4xl leading-normal text-white">{props.title}</p>
                <div className="w-full h-1 bg-haiyan-yellow mt-2 md:mt-4"></div>
            </div>
            <div className="case-menu flex flex-row justify-between">
                {springs.map((value, index) => (
                    <animated.p style={value} data-index={index} onClick={MenuClicked} key={index} className="menu-name 
                    font-sarasaNormal text-xs sm:text-sm md:text-lg ml-1 mr-1 md:ml-4 md:mr-4 tracking-tight sm:tracking-normal cursor-pointer">
                        {menus[index]}
                    </animated.p>
                ))}
            </div>
        </Parallax>
    )
}

export default CaseMenu;