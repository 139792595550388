import React from "react";
//css
import './Slider.css'
//lib
import { useDrag } from '@use-gesture/react'
import { useSpring, animated } from 'react-spring'
import { useState, useRef, useEffect } from 'react'
//component
import AnimateText1 from "../AnimateText1";


const CompareSlider = (props) => {

    const Slider = useRef();
    const [SliderX, setSliderX] = useState(0);

    useEffect(() => {
        const tempSize = Slider.current;

        setSliderX(tempSize.clientWidth);
        function handleResize() {
            const tempSize = Slider.current;
            setSliderX(tempSize.clientWidth);
        }
        window.addEventListener('resize', handleResize);
    }, [])

    const [{ x }, api] = useSpring(() => (
        { x: 0 }
    ));

    const bind = useDrag(
        ({ down, offset: [mx, my] }) => {
            api.start({ x: mx, immediate: down })
        },
        { axis: 'x', bounds: { left: -(SliderX / 2), right: SliderX / 2 } }
    )

    return (
        <div className="container relative overflow-hidden">
            <div ref={Slider} className="slider-wrap relative">
                <img className="block"
                    src={props.Before} alt="before" />
                <animated.div className="after-wrap"
                    style={{
                        width: x.to({
                            range: [-(SliderX / 2), SliderX / 2],
                            output: ['0%', '100%']
                        })
                    }}>
                    <img className="block"
                        src={props.After} alt="after" />
                </animated.div>
                <animated.div className="handler relative swiper-no-swiping" {...bind()}
                    style={{ x }}>
                    <div className="handler-circle">
                        <div class="triangle-container">
                            <svg class="triangle" >
                                <polygon points="5,10 15,0 15,20" />
                            </svg>
                        </div>
                        <div class="triangle-container">
                            <svg class="triangle" >
                                <polygon points="15,10 5,0 5,20" />
                            </svg>
                        </div>
                    </div>
                </animated.div>
            </div>
            <div className="animateTitle absolute w-screen 
            flex flex-col justify-center h-48 md:h-56 xl:h-60 
            bottom-12 lg:bottom-20">
                <AnimateText1 text1={props.text1} text2={props.text2} />
            </div>
        </div>
    )
}

export default CompareSlider