import React from "react";

import ServiceText from "../ServiceText";
import CorporatePart from "../CorporatePart";
import BubbleBackground from "../BubbleBackground";

import { useInView } from 'react-intersection-observer';
import { useSpring, animated } from 'react-spring';

import { MdOutlineMessage } from 'react-icons/md';
import { CgPlayListCheck } from 'react-icons/cg';
import { IoHammerOutline } from 'react-icons/io5';
import { GrDeliver } from 'react-icons/gr';
import { AiOutlineFileProtect } from 'react-icons/ai';

//util
import { IsPhone } from "../../utils/IsPhone";

const videoSrc = "https://haiyantest-1304231816.cos.ap-guangzhou.myqcloud.com/HaiyanResources/SomeImages/NightCity.m4v";
const gifSrc = "https://haiyantest-1304231816.cos.ap-guangzhou.myqcloud.com/HaiyanResources/SomeImages/GIF/carBackground.gif"

const ServiceSection = () => {
    const { ref, inView } = useInView({
        threshold: 0.1,
        delay: 500
    });
    const [titleRef, titleInView] = useInView({
        threshold: 0.1,
        triggerOnce: false
    });

    const fadeAnimation = useSpring({
        to: {
            transform: `translate3d(${inView ? 0 : 50}vw,0,${inView ? 0 : -50}px)`,
            opacity: inView ? 1 : 0
        }
    })

    const titleAnimation1 = useSpring({
        to: {
            transformOrigin: 'bottom',
            transform: `translate3d(0,${titleInView ? 0 : 2}vh,0) scale3d(1, ${titleInView ? 1 : 0}, 1)`,
            opacity: titleInView ? 1 : 0.5
        },
    })
    const titleAnimation2 = useSpring({
        to: {
            transformOrigin: 'bottom',
            transform: `translate3d(0,${titleInView ? 0 : 2}vh,0) scale3d(1, ${titleInView ? 1 : 0}, 1)`,
            opacity: titleInView ? 1 : 0.5
        },
        delay: 500
    })

    return (
        <div className="service-section relative">
            <BubbleBackground />
            <div ref={titleRef} className="flex flex-col ml-12 pt-10 mb-5 md:ml-20 md:pt-16 cursor-default">
                <animated.div style={titleAnimation1} className="text-haiyan-blue font-sweiXDBold text-3xl md:text-5xl tracking-widest mb-1 md:mb-3">海燕广告</animated.div>
                <animated.div style={titleAnimation2} className="text-haiyan-orange font-sarasaNormal text-xl md:text-3xl tracking-wide">二十五年老字号，专业广告设计安装</animated.div>
            </div>
            <div className="flex flex-col lg:flex-row justify-between items-center">
                <animated.div style={fadeAnimation} ref={ref}
                    className="grid grid-cols-2 gap-3 md:gap-6 w-5/6 md:w-2/3 mt-5 mb-5 md:mt-10 md:mb-16 md:ml-12">
                    <ServiceText text="平面Logo、海报设计" />
                    <ServiceText text="招牌制作及安装" />
                    <ServiceText text="发光灯箱" />
                    <ServiceText text="宣传海报、展架" />
                    <ServiceText text="亮化工程" />
                    <ServiceText text="交安标牌标线工程" />
                </animated.div>
                <div className="relative h-96 xl:w-1/2 flex items-center justify-center flex-shrink">
                    {
                        IsPhone() ? (<img className="relative rounded-md z-0 object-cover h-full w-full" src={gifSrc} alt="background"></img>)
                            : (<video autoPlay loop className="relative rounded-md z-0 object-cover h-full w-full" src={videoSrc}>
                            </video>)}
                    <pre className="absolute tracking-wider z-20 p-2 sm:p-5 xl:p-10 
                    text-white font-sarasaNormal leading-loose break-words
                    text-sm sm:text-base md:text-xl 2xl:text-2xl
                     bg-haiyan-blue bg-opacity-30 rounded-sm">
                        <p>
                            广告可以帮助品牌去强化用户对品牌的印象，
                        </p>
                        <p>
                            传递品牌形象和理念，
                        </p>
                        <p>
                            与用户连接，培养用户的心智。
                        </p>
                        <p>
                            让用户在有需求的时候能第一时间想起你，
                        </p>
                        <p>
                            从而去进一步消费。
                        </p>
                        <p>
                            在这样一个信息过载时代，
                        </p>
                        <p>
                            要让人们记住并认识你。
                        </p>
                        <p>
                            就需要通过广告，在人短暂注意力聚焦的地方，
                        </p>
                        <p>
                            不停的加深他们对你品牌的印象。
                        </p>
                    </pre>
                </div>
            </div>
            <p className="mt-10 mb-5 md:mt-6 md:mb-3 text-center text-xl 
            md:text-3xl font-sarasaNormal text-haiyan-blue">
                项目流程
            </p>
            <div className="corporate-process flex flex-row justify-around pb-8 md:pb-16">
                <CorporatePart
                    icon={<MdOutlineMessage className=" h-4/5 w-4/5" />}
                    index="1"
                    title="咨询评估"
                />
                <CorporatePart
                    icon={<CgPlayListCheck className=" h-4/5 w-4/5" />}
                    index="2"
                    title="签订合同"
                />
                <CorporatePart
                    icon={<IoHammerOutline className=" h-4/5 w-4/5" />}
                    index="3"
                    title="生产制作"
                />
                <CorporatePart
                    icon={<GrDeliver className=" h-4/5 w-4/5" />}
                    index="4"
                    title="运输安装"
                />
                <CorporatePart
                    icon={<AiOutlineFileProtect className=" h-4/5 w-4/5" />}
                    index="5"
                    title="维护保修"
                />
            </div>
        </div>
    )
}

export default ServiceSection;