import React from 'react';
import { Parallax, Background } from 'react-parallax';
const Separator = (props) => {

    return (
        <Parallax className="flex relative
         items-center justify-center h-24 md:h-36"
            blur={{ min: -1, max: 1 }}
            strength={250} bgImage={props.bgSrc}>
            <Background>
                <div style={{position:'absolute',top:500,height:3500,width:2000}}>
                </div>
            </Background>
            <p className="font-sarasaNormal text-2xl md:text-4xl leading-normal text-white">{props.title}</p>
            <div className="w-full h-1 bg-haiyan-yellow mt-2 md:mt-4"></div>
        </Parallax>
    )
}

export default Separator;