import React from "react";

//component
import BottomInfo from "../BottomInfo";

import "./index.css";


const Footer = (e) => {
    return (
        <div className="footer">
            <BottomInfo />
            <div className="flex flex-row justify-evenly">
                <a className="text-center text-xs whitespace-pre" href="https://beian.miit.gov.cn/">粤ICP备2022045121号</a>
                <div>
                    <a className="flex flex-row text-xs" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44132202100211">
                        <img src="https://haiyantest-1304231816.cos.ap-guangzhou.myqcloud.com/BeianResources/Beian.webp" alt="beianIcon"/>
                        <p>粤公网安备 44132202100211号</p>
                    </a>
                </div>
                <div className="text-center text-xs whitespace-pre">©2022 by HaiYan Ads Limited</div>
            </div>
        </div>
    )
}

export default Footer;