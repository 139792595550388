import React from "react";

import ContentBox from '../ContentBox'
import './DisplaySection.css'

const DisplaySection = () => {
    return (
        <div>
            <div className="DisplaySection flex flex-col md:flex-row
             justify-center items-center md:items-start w-screen mt-10">
                <ContentBox title="专业的团队" description="我们是来自不同年龄层的人，
                    有高瞻远瞩、运筹帷幄的领导者，
                    也有别出心裁的设计师，
                    更有一流水准的安装工。
                    我们每个人都勇敢挑战自我，超越自我"
                    fileSrc="https://haiyantest-1304231816.cos.ap-guangzhou.myqcloud.com/HaiyanResources/SomeImages/HomepageImages/team.webp" />
                <ContentBox title="丰富的经验" description="海燕自上世纪90年代起扎根于广告行业，
                        现历经风雨二十余载，
                        岁月让海燕沉淀丰富的经验，
                        岁月也促使着海燕不断开拓创新、与时俱进。"
                    fileSrc="https://haiyantest-1304231816.cos.ap-guangzhou.myqcloud.com/HaiyanResources/SomeImages/HomepageImages/business.webp" />
                <ContentBox title="优质的材料" description="海燕承诺对每一位客户都用心、真心。
                        在材料选品上用诚心换客户的安心。"
                    fileSrc="https://haiyantest-1304231816.cos.ap-guangzhou.myqcloud.com/HaiyanResources/SomeImages/HomepageImages/workers.webp" />
            </div>
        </div>
    )
}

export default DisplaySection;