import React, { useState } from "react";
import { useSpring, animated } from "react-spring";

const NavHamburger = (props) => {
    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const burger1 = useSpring({
        to: {
            transform: `rotate(${menuIsOpen ? 43 : 0}deg)`,
            background: `${menuIsOpen ? "#0F57A0" : "#0033AA"}`
        }
    });
    const burger2 = useSpring({
        to: {
            transform: `rotate(${menuIsOpen ? -43 : 0}deg)`,
            background: `${menuIsOpen ? "#0F57A0" : "#0033AA"}`
        }
    });
    const burger3 = useSpring({
        to: {
            transform: `translate(${menuIsOpen ? -3 : 0}vw,0)`,
            opacity: menuIsOpen ? 0 : 1
        }
    });

    const openMenu = () => {
        setMenuIsOpen(!menuIsOpen);
        props.setExpand(!props.expand);
    }

    return (
        <div className="nav-burger flex flex-col sm:hidden h-6 ml-6
                    justify-around cursor-pointer"
            onClick={openMenu}>
            <animated.div className="h-1 w-6 bg-haiyan-blue origin-left"
                style={burger1}>
            </animated.div>
            <animated.div style={burger3}
                className="h-1 w-6 bg-haiyan-blue origin-left">
            </animated.div>
            <animated.div style={burger2}
                className="h-1 w-6 bg-haiyan-blue origin-left">
            </animated.div>
        </div>
    )
}

export default NavHamburger;