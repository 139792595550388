//lib
import React, { useState, useEffect } from "react"
import { useSprings, animated } from "react-spring"

//css
import "./index.css"
const HorseLight = (e) => {
    const [currentText, setCurrentText] = useState(null);
    const planItems = ['店铺门头', '宣传物料', '形象墙', '建筑亮化'];
    const [planAnimations, api] = useSprings(planItems.length, (index) => ({
        from: { transform: `translate3d(0, -50px, 0)` },
    }));
    api.start(index => ({
        pause: (index === currentText) ? false : true,
        to: async (next, cancel) => {
            await next({ to: { transform: `translate3d(0, 0px, 0)` } })
            await next({ to: { transform: `translate3d(0, 50px, 0)` }, delay: 2000 })
        },
        reset: true
    }));

    useEffect(() => {
        const interval = setInterval(() => {
            if (currentText > planItems.length - 2) {
                setCurrentText(0);
            } else {
                setCurrentText(currentText + 1);
            }
        }, 3000);
        return () => {
            clearInterval(interval)
        }
    }, [currentText]);

    return (
        <div className="relative overflow-hidden text-lg flex flex-row">
            <div className="relative">定制你的专属</div>
            <div className="h-8 w-20 md:w-24 relative">
                {
                    planAnimations.map((value, index) => (
                        <animated.div className="horseText absolute text-center" style={value}>{planItems[index]}</animated.div>
                    ))
                }
            </div>
            <div className="relative">方案</div>
        </div>
    )
}

export default HorseLight;