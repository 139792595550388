import React from "react";
//Lib
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react'
import { Pagination,Navigation } from "swiper";
import CompareSlider from '../CompareSlider';
import NormalSlider from '../NormalSlider';
//css
import 'swiper/swiper-bundle.css'

const WebSwiper = (props) => {

    return (
        <Swiper
            modules={[Pagination,Navigation]}
            spaceBetween={50}
            slidesPerView={1}
            navigation={true}
            pagination={{ clickable: true }}
            preventClicksPropagation={true}
        >
            <SwiperSlide>
                {props.isPhone ? (
                    <NormalSlider text1="洞悉市场需求，推导差异化" text2="用视觉创造品牌营销力"
                        imgSrc="https://haiyantest-1304231816.cos.ap-guangzhou.myqcloud.com/HaiyanResources/Cases/GIF/beimunan.gif" />
                )
                    :
                    (
                        <CompareSlider text1="提供一站式品牌广告服务" text2="设计 制作 安装 维保让你省心"
                            Before="https://haiyantest-1304231816.cos.ap-guangzhou.myqcloud.com/HaiyanResources/Cases/Images/WEBP/WEBP/yinghuayuanBefore.webp"
                            After="https://haiyantest-1304231816.cos.ap-guangzhou.myqcloud.com/HaiyanResources/Cases/Images/WEBP/WEBP/yinghuayuanAfter.webp" />
                    )
                }
            </SwiperSlide>
            <SwiperSlide>
                {props.isPhone ? (
                    <NormalSlider text1="提供一站式品牌广告服务" text2="设计 制作 安装 维保让你省心"
                        imgSrc="https://haiyantest-1304231816.cos.ap-guangzhou.myqcloud.com/HaiyanResources/Cases/GIF/tonghudiaoshu.gif" />
                )
                    :
                    (
                        <NormalSlider text1="洞悉市场需求，推导差异化" text2="用视觉创造品牌营销力"
                            imgSrc="https://haiyantest-1304231816.cos.ap-guangzhou.myqcloud.com/HaiyanResources/Cases/Images/WEBP/WEBP/manzhoujinxiao.webp" />
                    )
                }
            </SwiperSlide>
            <SwiperSlide>
                {props.isPhone ? (
                    <NormalSlider text1="提升品牌对外形象" text2="与你共同加强品牌竞争力"
                        imgSrc="https://haiyantest-1304231816.cos.ap-guangzhou.myqcloud.com/HaiyanResources/Cases/Images/WEBP/WEBP/zuolingyousheVertical.webp" />
                )
                    :
                    (
                        <CompareSlider text1="提升品牌对外形象" text2="与你共同加强品牌竞争力"
                            Before="https://haiyantest-1304231816.cos.ap-guangzhou.myqcloud.com/HaiyanResources/Cases/Images/WEBP/WEBP/xiaoyunlongBefore.webp"
                            After="https://haiyantest-1304231816.cos.ap-guangzhou.myqcloud.com/HaiyanResources/Cases/Images/WEBP/WEBP/xiaoyunlongAfter.webp" />
                    )
                }
            </SwiperSlide>
        </Swiper>
    )
}

export default WebSwiper