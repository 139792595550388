//lib
import React from "react";

//component
import AnimateText1 from "../AnimateText1";

const NormalSlider = (props) => {

    return (
        <div className="container relative overflow-hidden">
            <img className="block w-screen h-screen object-cover"
                src={props.imgSrc} alt={props.imgSrc} />
            <div className="animateTitle absolute w-screen flex flex-col justify-center
                            h-48 md:h-56 xl:h-60 bottom-12 lg:bottom-20">
                <AnimateText1 text1={props.text1} text2={props.text2} />
            </div>
        </div>
    )
}

export default NormalSlider