//lib
import React from "react";

//component
import CustomerBlock from "../CustomerBlock";

const CustomerPart = (e) => {
    return (
        <div className="flex flex-col">
            <div className="flex flex-row justify-evenly mt-3">
                <CustomerBlock title="中国人寿" logoSrc="https://haiyantest-1304231816.cos.ap-guangzhou.myqcloud.com/HaiyanResources/Logo/CustomerLogo/zhongguorenshouLogo.png" />
                <CustomerBlock title="众成蘑菇" logoSrc="https://haiyantest-1304231816.cos.ap-guangzhou.myqcloud.com/HaiyanResources/Logo/CustomerLogo/zhongchengLogo.png" />
                <CustomerBlock title="阳光保险" logoSrc="https://haiyantest-1304231816.cos.ap-guangzhou.myqcloud.com/HaiyanResources/Logo/CustomerLogo/yangguangLogo.png" />
                <CustomerBlock title="小云龙" logoSrc="https://haiyantest-1304231816.cos.ap-guangzhou.myqcloud.com/HaiyanResources/Logo/CustomerLogo/xiaoyunlongLogo.png" />
            </div>
            <div className="flex flex-row justify-evenly mt-3">
                <CustomerBlock title="铁建" logoSrc="https://haiyantest-1304231816.cos.ap-guangzhou.myqcloud.com/HaiyanResources/Logo/CustomerLogo/tiejianLogo.png" />
                <CustomerBlock title="穗康医药" logoSrc="https://haiyantest-1304231816.cos.ap-guangzhou.myqcloud.com/HaiyanResources/Logo/CustomerLogo/suikang.png" />
                <CustomerBlock title="中国石化" logoSrc="https://haiyantest-1304231816.cos.ap-guangzhou.myqcloud.com/HaiyanResources/Logo/CustomerLogo/sinopecLogo.png" />
                <CustomerBlock title="启正幼儿园" logoSrc="https://haiyantest-1304231816.cos.ap-guangzhou.myqcloud.com/HaiyanResources/Logo/CustomerLogo/qizhengLogo.png" />
            </div>
            <div className="flex flex-row justify-evenly mt-3">
                <CustomerBlock title="惠兴建材" logoSrc="https://haiyantest-1304231816.cos.ap-guangzhou.myqcloud.com/HaiyanResources/Logo/CustomerLogo/huixinLogo.png" />
                <CustomerBlock title="北木南" logoSrc="https://haiyantest-1304231816.cos.ap-guangzhou.myqcloud.com/HaiyanResources/Logo/CustomerLogo/beimunanLogo.png" />
                <CustomerBlock title="奥园" logoSrc="https://haiyantest-1304231816.cos.ap-guangzhou.myqcloud.com/HaiyanResources/Logo/CustomerLogo/aoyuanLogo.png" />
                <CustomerBlock title="澳凯" logoSrc="https://haiyantest-1304231816.cos.ap-guangzhou.myqcloud.com/HaiyanResources/Logo/CustomerLogo/aokai.png" />
            </div>
        </div>
    )
}

export default CustomerPart;